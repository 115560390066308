// src/pages/NotFound.js

import React from 'react';
// import { Link } from 'react-router-dom';

function Subscribe() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Subscribe</h1>
      
    </div>
  );
}

export default Subscribe;
